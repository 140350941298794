import React from 'react';
import { CartesianGrid, Legend, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts';
import { COMMON_CHART_PROPS, fillColor, X_AXIS_DATA_KEY, Y_AXIS_DATA_KEY } from './chartUtils';

type Props = {
    data: any[];
    width?: number;
};

export const DukeScatterChart = ({ data, width = COMMON_CHART_PROPS.width }: Props) => {
    return (
        <ScatterChart {...{ ...COMMON_CHART_PROPS, ...{ width } }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={X_AXIS_DATA_KEY} allowDuplicatedCategory={false} />
            <YAxis dataKey={Y_AXIS_DATA_KEY} />
            <Tooltip cursor={{ strokeDasharray: '2 5' }} />
            <Legend verticalAlign="top" />
            {data.map((s, idx) => (
                <>
                    {s.name ? (
                        <Scatter data={s.data} name={s.name} key={s.name} fill={fillColor(idx)} />
                    ) : (
                        <Scatter legendType="none" dataKey="none" />
                    )}
                </>
            ))}
        </ScatterChart>
    );
};
