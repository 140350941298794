import React from 'react';
import { HomePageHeaderNavBar } from '../../home/HomePageHeaderNavBar';
import { DukeChatContainer } from './DukeChatContainer';

export const ChatPage = () => {
    return (
        <>
            <HomePageHeaderNavBar />
            <DukeChatContainer />
        </>
    );
};
