import React from 'react';
import { Badge, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

type Props = {
    title: any;
};

export const MessageTooltip = ({ title }: Props) => {
    return (
        <Tooltip title={title}>
            <Badge style={{ margin: '-17px -9px 0 0' }} count={<InfoCircleOutlined />} />
        </Tooltip>
    );
};
