import React from 'react';
import { Image } from 'antd';
import { useTheme } from 'styled-components/macro';
import { DEFAULT_APP_CONFIG } from '../../../appConfigContext';
import { useAppConfig } from '../../useAppConfig';

interface Props {
    style?: React.CSSProperties;
    onClick?: () => void;
}

export function DukeLogo({ style, onClick }: Props) {
    const appConfig = useAppConfig();
    const themeConfig = useTheme();

    return (
        <Image
            src={
                appConfig.config !== DEFAULT_APP_CONFIG
                    ? appConfig.config.visualConfig.logoUrl || themeConfig.assets.logoUrl
                    : '/assets/logo.png'
            }
            preview={false}
            onClick={() => onClick && onClick()}
            style={style}
        />
    );
}
