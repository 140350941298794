import React, { useState } from 'react';
import { message, Button, Input, Modal, Typography, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCreateUserMutation } from '../../../graphql/duke.generated';
import { CreateUserInput } from '../../../types.generated';

type CreateUserModalProps = {
    visible: boolean;
    onClose: () => void;
    onCreate: (createGroupUserInput: CreateUserInput) => void;
};

export default function CreateUserModal({ visible, onClose, onCreate }: CreateUserModalProps) {
    const [stagedDisplayName, setStagedDisplayName] = useState('');
    const [stagedFullName, setStagedFullName] = useState('');
    const [stagedEmail, setStagedEmail] = useState('');
    const [stagedTitle, setStagedTitle] = useState('');
    const [createUserMutation] = useCreateUserMutation();
    const { t } = useTranslation();

    const onCreateUser = () => {
        const createUserInput: CreateUserInput = {
            active: true,
            displayName: stagedDisplayName,
            fullName: stagedFullName,
            email: stagedEmail,
            title: stagedTitle,
        };
        createUserMutation({
            variables: {
                input: createUserInput,
            },
        })
            .catch((e) => {
                message.destroy();
                message.error({
                    content: `${t('crud.error.createWithName', { name: t('common.user') })}:\n ${e.message || ''}`,
                    duration: 3,
                });
            })
            .finally(() => {
                message.success({
                    content: t('crud.success.createWithName', { name: t('common.user') }),
                    duration: 3,
                });
                onCreate(createUserInput);
                setStagedDisplayName('');
                setStagedFullName('');
                setStagedEmail('');
                setStagedTitle('');
            });
        onClose();
    };

    return (
        <Modal
            title={t('user.createUserModal.title')}
            visible={visible}
            onCancel={onClose}
            footer={
                <>
                    <Button onClick={onClose} type="text">
                        {t('common.cancel')}
                    </Button>
                    <Button
                        onClick={onCreateUser}
                        disabled={stagedDisplayName === '' || stagedFullName === '' || stagedEmail === ''}
                    >
                        {t('common.create')}
                    </Button>
                </>
            }
        >
            <Form layout="vertical">
                <Form.Item
                    name="displayName"
                    label={<Typography.Text strong>{t('user.createUserModal.displayNameLabel')}</Typography.Text>}
                >
                    <Typography.Paragraph>{t('user.createUserModal.displayNameDescription')}</Typography.Paragraph>
                    <Input
                        required
                        placeholder={t('user.createUserModal.displayNamePlaceholder')}
                        value={stagedDisplayName}
                        onChange={(event) => setStagedDisplayName(event.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    name="fullName"
                    label={<Typography.Text strong>{t('user.createUserModal.fullNameLabel')}</Typography.Text>}
                >
                    <Typography.Paragraph>{t('user.createUserModal.fullNameDescription')}</Typography.Paragraph>
                    <Input
                        required
                        placeholder={t('user.createUserModal.fullNamePlaceholder')}
                        value={stagedFullName}
                        onChange={(event) => setStagedFullName(event.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    name="email"
                    label={<Typography.Text strong>{t('user.createUserModal.emailLabel')}</Typography.Text>}
                >
                    <Typography.Paragraph>{t('user.createUserModal.emailDescription')}</Typography.Paragraph>
                    <Input
                        required
                        type="email"
                        placeholder={t('user.createUserModal.emailPlaceholder')}
                        value={stagedEmail}
                        onChange={(event) => setStagedEmail(event.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    name="title"
                    label={<Typography.Text strong>{t('user.createUserModal.titleLabel')}</Typography.Text>}
                >
                    <Typography.Paragraph>{t('user.createUserModal.titleDescription')}</Typography.Paragraph>
                    <Input
                        placeholder={t('user.createUserModal.titlePlaceholder')}
                        value={stagedTitle}
                        onChange={(event) => setStagedTitle(event.target.value)}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}
