import { Select } from 'antd';
import React from 'react';
import styled from 'styled-components/macro';

import { useTranslation } from 'react-i18next';
import { ANTD_GRAY } from '../entity/shared/constants';
import { UnionType } from './utils/constants';

type Props = {
    unionType: UnionType;
    onUpdate: (newValue: UnionType) => void;
    disabled?: boolean;
};

const { Option } = Select;

const StyledSelect = styled(Select)`
    border-radius: 5px;
    background: ${ANTD_GRAY[4]};
    :hover {
        background: ${ANTD_GRAY[4.5]};
    }
`;

export const AdvancedSearchFilterOverallUnionTypeSelect = ({ unionType, onUpdate, disabled = false }: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <StyledSelect
                showArrow={false}
                bordered={false}
                disabled={disabled}
                // these values are just for display purposes- the actual value is the unionType prop
                value={unionType === UnionType.AND ? t('filter.allFilters') : t('filter.anyFilter')}
                onChange={(newValue) => {
                    if ((newValue as any) !== unionType) {
                        onUpdate(newValue as any);
                    }
                }}
                size="small"
                dropdownMatchSelectWidth={false}
            >
                <Option value={UnionType.AND}>{t('filter.allFilters')}</Option>
                <Option value={UnionType.OR}>{t('filter.anyFilter')}</Option>
            </StyledSelect>
        </>
    );
};
