import React from 'react';
import { UploadProps } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface Props {
    draggerProps: UploadProps;
    hint?: string | JSX.Element;
}

export function DukeDragger({ draggerProps, hint }: Props) {
    const { t } = useTranslation();

    return (
        <Dragger {...draggerProps}>
            <>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t('dukeChat.searchInUploadedFile.text')}</p>
                {hint && <p className="ant-upload-hint">{hint}</p>}
            </>
        </Dragger>
    );
}
