import React from 'react';
import { useTranslation } from 'react-i18next';
import { Message } from '@chatscope/chat-ui-kit-react';
import { EntityNameListRenderer } from '../../../recommendations/renderer/EntityNameListRenderer';
import { RecommendationRenderType, ScenarioType } from '../../../../types.generated';
import { RecommendationDisplayType } from '../../../recommendations/types';
import { MessageTooltip } from './component/MessageTooltip';

type Props = {
    messageParams: any;
};

export const EntityFoundMessageComponent = ({ messageParams }: Props) => {
    const { t } = useTranslation();

    return (
        <>
            {messageParams.entities?.length
                ? t('dukeChat.entitiesFound_interval', {
                      postProcess: 'interval',
                      count: messageParams.entities.length || 0,
                  })
                : t('dukeChat.noEntitiesFound')}
            <MessageTooltip
                title={`${t('common.concept')}: ${messageParams.concept}\n${t('common.filter')}: ${
                    messageParams.filters
                }`}
            />
            {messageParams.entities.length ? (
                <Message.CustomContent>
                    <div style={{ background: 'white', borderRadius: '10px' }}>
                        <EntityNameListRenderer
                            renderId="idRandom"
                            moduleId="ChatMessage_idRandom"
                            scenarioType={ScenarioType.EntityProfile}
                            renderType={RecommendationRenderType.EntityNameList}
                            content={messageParams.entities}
                            displayType={RecommendationDisplayType.COMPACT}
                        />
                    </div>
                </Message.CustomContent>
            ) : null}
        </>
    );
};
