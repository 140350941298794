import React from 'react';
import { Card, Divider, Typography } from 'antd';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useChat } from './ChatProvider';
import { DukeLogo } from '../component/DukeLogo';

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const FlexRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
`;

const ChatTitle = styled(Typography.Title)`
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
`;

const ThinDivider = styled(Divider)`
    margin-top: 12px;
    margin-bottom: 12px;
`;

const ExempleCard = styled(Card)`
    margin-right: 1rem;
    font-size: 0.8rem;
`;

const ExempleList: string[] = [
    'dukeChat.intro.example1',
    'dukeChat.intro.example2',
    'dukeChat.intro.example3',
    'dukeChat.intro.example4',
];

const Parag = styled.div`
    margin-top: 1rem;
    padding-left: 1rem;
`;

const PointText = styled(Typography.Text)`
    font-size: 1rem;
`;

export const EmptyChatComponent = () => {
    const { t } = useTranslation();
    const { sendMessage } = useChat();

    return (
        <HeaderContainer>
            <FlexRowContainer>
                <DukeLogo style={{ width: 60 }} />
                <ChatTitle style={{ marginLeft: '1rem' }} level={4}>
                    DUKE
                </ChatTitle>
            </FlexRowContainer>
            <ChatTitle style={{ marginTop: '1rem' }} level={4}>
                {t('dukeChat.intro.intro')}
            </ChatTitle>
            <ThinDivider />
            <Parag>
                <PointText>{t('dukeChat.intro.point1')}</PointText>
                <br />
                <PointText type="secondary">{t('dukeChat.intro.detail1')}</PointText>
            </Parag>
            <Parag>
                <PointText>{t('dukeChat.intro.point2')}</PointText>
                <br />
                <PointText type="secondary">{t('dukeChat.intro.detail2')}</PointText>
            </Parag>
            <Parag>
                <PointText>{t('dukeChat.intro.point3')}</PointText>
                <br />
                <PointText type="secondary">{t('dukeChat.intro.detail3')}</PointText>
            </Parag>
            <ChatTitle style={{ marginTop: '1rem' }} level={4}>
                {t('common.exemples')}
            </ChatTitle>
            <ThinDivider />
            <FlexRowContainer>
                {ExempleList.map((e) => (
                    <ExempleCard hoverable onClick={() => sendMessage(t(e))}>
                        {t(e)}
                    </ExempleCard>
                ))}
            </FlexRowContainer>
        </HeaderContainer>
    );
};
