export const COMMON_CHART_PROPS = {
    width: 600,
    height: 300,
};

export const PALETTE_COULEUR = ['#008d98', '#f06a63', '#ffcc3d', '#d7433b', '#95caa6', '#ff8e5e'];
export const fillColor = (idx = 0) => PALETTE_COULEUR[idx % PALETTE_COULEUR.length];

export const DEFAULT_CHART = 'bar';
export const X_AXIS_DATA_KEY = 'category';
export const Y_AXIS_DATA_KEY = 'value';

export const buildChartData = (serieList: any): any => {
    let series = serieList;
    if (!Array.isArray(series)) {
        try {
            series = JSON.parse(serieList);
            if (!Array.isArray(series)) {
                console.log(`Les series ne sont pas au bon format : ${serieList}`);
                return [];
            }
        } catch (error) {
            console.log(error);
            return [];
        }
    }
    try {
        // Pour les Bar Chart
        // Pour pouvoir afficher les légendes, ils faut qu'il y ai autant de séries
        // que de point d'abscisses... Très bizarre -_-
        const absList: string[] = [];
        series.forEach((serie) => {
            (serie.data || []).forEach((point) => {
                const absLabel: string = point[X_AXIS_DATA_KEY];
                if (absList.indexOf(absLabel) < 0) {
                    absList.push(absLabel);
                }
            });
        });
        for (let i = series.length; i < absList.length; i++) {
            series.push({});
        }

        const seriesName: string[] = [];
        return series.map((s) => {
            // Check if there is multiples series with the same name
            const count = seriesName.reduce((nbr, element) => {
                return nbr + (element === s.name ? 1 : 0);
            }, 0);

            seriesName.push(s.name);
            return count > 0 && s.name
                ? {
                      ...s,
                      ...{ name: `${s.name}(${count})` },
                  }
                : s;
        });
    } catch (error) {
        console.error('Une erreur est survenue lors de la construction des données du graphique :', error.message);
        console.log(series);
        return [];
    }
};
