import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TabToolbar from '../../../components/styled/TabToolbar';
import { DukeLogo } from '../../../../../duke/component/DukeLogo';

type DescriptionEditorToolbarProps = {
    disableSave: boolean;
    onClose: () => void;
    onSave: () => void;
    onGetDescriptionFromDuke: () => void;
    isDukeLoading: boolean;
};

export const DescriptionEditorToolbar = ({
    disableSave,
    onClose,
    onSave,
    onGetDescriptionFromDuke,
    isDukeLoading,
}: DescriptionEditorToolbarProps) => {
    const { t } = useTranslation();
    return (
        <TabToolbar>
            <Button type="text" onClick={onClose}>
                {t('common.backAction')}
            </Button>
            <Button onClick={onGetDescriptionFromDuke}>
                {t('common.ask')}
                {isDukeLoading ? <LoadingOutlined /> : <DukeLogo style={{ width: 20, margin: '0 0 0 1rem' }} />}
            </Button>
            <Button onClick={onSave} disabled={disableSave}>
                <CheckOutlined /> {t('common.save')}
            </Button>
        </TabToolbar>
    );
};
