import { Button, Input, Tooltip } from 'antd';
import { LoadingOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TabToolbar from '../../../components/styled/TabToolbar';
import { DukeLogo } from '../../../../../duke/component/DukeLogo';

const StyledInput = styled(Input)`
    border-radius: 70px;
    max-width: 300px;
`;

type Props = {
    addQueryDisabled: boolean;
    onAddQuery: () => void;
    onChangeSearch: (text: any) => void;
    onCreateRequestFromDuke: () => void;
    isDukeLoading: boolean;
};

export default function QueriesTabToolbar({
    addQueryDisabled,
    onAddQuery,
    onChangeSearch,
    onCreateRequestFromDuke,
    isDukeLoading,
}: Props) {
    const { t } = useTranslation();

    return (
        <TabToolbar>
            <Tooltip
                placement="right"
                title={
                    (addQueryDisabled && t('entity.entity.notAuthorizedToAddQueriesToEntity')) ||
                    t('entity.addAHighlightedQuery')
                }
            >
                <Button disabled={addQueryDisabled} type="text" onClick={onAddQuery} data-testid="add-query-button">
                    <PlusOutlined /> {t('crud.addWithName', { name: t('common.query') })}
                </Button>
                <Button onClick={onCreateRequestFromDuke}>
                    {t('common.ask')}
                    {isDukeLoading ? <LoadingOutlined /> : <DukeLogo style={{ width: 20, margin: '0 0 0 1rem' }} />}
                </Button>
            </Tooltip>
            <StyledInput
                placeholder={t('placeholder.searchInWithName', { name: t('common.queries').toLowerCase() })}
                onChange={onChangeSearch}
                allowClear
                prefix={<SearchOutlined />}
                data-testid="search-query-input"
            />
        </TabToolbar>
    );
}
