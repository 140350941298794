import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { COMMON_CHART_PROPS, fillColor, X_AXIS_DATA_KEY, Y_AXIS_DATA_KEY } from './chartUtils';

type Props = {
    data: any[];
    width?: number;
};

export const DukeBarChart = ({ data, width = COMMON_CHART_PROPS.width }: Props) => {
    return (
        <BarChart {...{ ...COMMON_CHART_PROPS, ...{ width } }} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={X_AXIS_DATA_KEY} allowDuplicatedCategory={false} />
            <YAxis />
            <Tooltip filterNull={false} />
            <Legend verticalAlign="top" />
            {data.map((s: any, idx) => (
                <>
                    {s.name ? (
                        <Bar
                            barSize={25}
                            data={s.data}
                            name={s.name}
                            key={s.name}
                            fill={fillColor(idx)}
                            dataKey={Y_AXIS_DATA_KEY}
                        />
                    ) : (
                        <Bar legendType="none" dataKey="none" />
                    )}
                </>
            ))}
        </BarChart>
    );
};
