/* eslint-disable */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetUserConversationListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetUserConversationListQuery = { __typename?: 'Query' } & {
    getUserConversationList: Array<
        Types.Maybe<
            { __typename?: 'DukeConversation' } & Pick<
                Types.DukeConversation,
                'username' | 'conversationId' | 'conversationDateMilli' | 'historicalConversationDateMilli'
            >
        >
    >;
};

export type GetActualLicenceQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetActualLicenceQuery = { __typename?: 'Query' } & {
    getActualLicence?: Types.Maybe<
        { __typename?: 'DukeLicence' } & Pick<
            Types.DukeLicence,
            'licenceId' | 'nbrOfAdmin' | 'nbrOfAnalyst' | 'licenceStartDateMilli' | 'licenceEndDateMilli' | 'isActive'
        >
    >;
};

export type DeleteConversationMutationVariables = Types.Exact<{
    conversationId: Types.Scalars['String'];
}>;

export type DeleteConversationMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'deleteConversation'>;

export type ChangeLicenceStatusMutationVariables = Types.Exact<{
    dukeLicenceId: Types.Scalars['String'];
}>;

export type ChangeLicenceStatusMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'changeLicenceStatus'>;

export type CreateUserMutationVariables = Types.Exact<{
    input: Types.CreateUserInput;
}>;

export type CreateUserMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'createUser'>;

export type UpdateUserPasswordMutationVariables = Types.Exact<{
    input: Types.UpdateUserPasswordInput;
}>;

export type UpdateUserPasswordMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'updateUserPassword'>;

export type UpdateDukeParametersMutationVariables = Types.Exact<{
    dukeParameters: Types.DukeParametersInput;
}>;

export type UpdateDukeParametersMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'updateDukeParameters'>;

export type SaveDukeConversationMutationVariables = Types.Exact<{
    conversationId: Types.Scalars['String'];
    historicalConversationDateMilli?: Types.Maybe<Types.Scalars['Long']>;
}>;

export type SaveDukeConversationMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'saveDukeConversation'>;

export const GetUserConversationListDocument = gql`
    query getUserConversationList {
        getUserConversationList {
            username
            conversationId
            conversationDateMilli
            historicalConversationDateMilli
        }
    }
`;

/**
 * __useGetUserConversationListQuery__
 *
 * To run a query within a React component, call `useGetUserConversationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserConversationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserConversationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserConversationListQuery(
    baseOptions?: Apollo.QueryHookOptions<GetUserConversationListQuery, GetUserConversationListQueryVariables>,
) {
    return Apollo.useQuery<GetUserConversationListQuery, GetUserConversationListQueryVariables>(
        GetUserConversationListDocument,
        baseOptions,
    );
}
export function useGetUserConversationListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetUserConversationListQuery, GetUserConversationListQueryVariables>,
) {
    return Apollo.useLazyQuery<GetUserConversationListQuery, GetUserConversationListQueryVariables>(
        GetUserConversationListDocument,
        baseOptions,
    );
}
export type GetUserConversationListQueryHookResult = ReturnType<typeof useGetUserConversationListQuery>;
export type GetUserConversationListLazyQueryHookResult = ReturnType<typeof useGetUserConversationListLazyQuery>;
export type GetUserConversationListQueryResult = Apollo.QueryResult<
    GetUserConversationListQuery,
    GetUserConversationListQueryVariables
>;
export const GetActualLicenceDocument = gql`
    query getActualLicence {
        getActualLicence {
            licenceId
            nbrOfAdmin
            nbrOfAnalyst
            licenceStartDateMilli
            licenceEndDateMilli
            isActive
        }
    }
`;

/**
 * __useGetActualLicenceQuery__
 *
 * To run a query within a React component, call `useGetActualLicenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActualLicenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActualLicenceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActualLicenceQuery(
    baseOptions?: Apollo.QueryHookOptions<GetActualLicenceQuery, GetActualLicenceQueryVariables>,
) {
    return Apollo.useQuery<GetActualLicenceQuery, GetActualLicenceQueryVariables>(
        GetActualLicenceDocument,
        baseOptions,
    );
}
export function useGetActualLicenceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetActualLicenceQuery, GetActualLicenceQueryVariables>,
) {
    return Apollo.useLazyQuery<GetActualLicenceQuery, GetActualLicenceQueryVariables>(
        GetActualLicenceDocument,
        baseOptions,
    );
}
export type GetActualLicenceQueryHookResult = ReturnType<typeof useGetActualLicenceQuery>;
export type GetActualLicenceLazyQueryHookResult = ReturnType<typeof useGetActualLicenceLazyQuery>;
export type GetActualLicenceQueryResult = Apollo.QueryResult<GetActualLicenceQuery, GetActualLicenceQueryVariables>;
export const DeleteConversationDocument = gql`
    mutation deleteConversation($conversationId: String!) {
        deleteConversation(conversationId: $conversationId)
    }
`;
export type DeleteConversationMutationFn = Apollo.MutationFunction<
    DeleteConversationMutation,
    DeleteConversationMutationVariables
>;

/**
 * __useDeleteConversationMutation__
 *
 * To run a mutation, you first call `useDeleteConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConversationMutation, { data, loading, error }] = useDeleteConversationMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useDeleteConversationMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteConversationMutation, DeleteConversationMutationVariables>,
) {
    return Apollo.useMutation<DeleteConversationMutation, DeleteConversationMutationVariables>(
        DeleteConversationDocument,
        baseOptions,
    );
}
export type DeleteConversationMutationHookResult = ReturnType<typeof useDeleteConversationMutation>;
export type DeleteConversationMutationResult = Apollo.MutationResult<DeleteConversationMutation>;
export type DeleteConversationMutationOptions = Apollo.BaseMutationOptions<
    DeleteConversationMutation,
    DeleteConversationMutationVariables
>;
export const ChangeLicenceStatusDocument = gql`
    mutation changeLicenceStatus($dukeLicenceId: String!) {
        changeLicenceStatus(dukeLicenceId: $dukeLicenceId)
    }
`;
export type ChangeLicenceStatusMutationFn = Apollo.MutationFunction<
    ChangeLicenceStatusMutation,
    ChangeLicenceStatusMutationVariables
>;

/**
 * __useChangeLicenceStatusMutation__
 *
 * To run a mutation, you first call `useChangeLicenceStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeLicenceStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeLicenceStatusMutation, { data, loading, error }] = useChangeLicenceStatusMutation({
 *   variables: {
 *      dukeLicenceId: // value for 'dukeLicenceId'
 *   },
 * });
 */
export function useChangeLicenceStatusMutation(
    baseOptions?: Apollo.MutationHookOptions<ChangeLicenceStatusMutation, ChangeLicenceStatusMutationVariables>,
) {
    return Apollo.useMutation<ChangeLicenceStatusMutation, ChangeLicenceStatusMutationVariables>(
        ChangeLicenceStatusDocument,
        baseOptions,
    );
}
export type ChangeLicenceStatusMutationHookResult = ReturnType<typeof useChangeLicenceStatusMutation>;
export type ChangeLicenceStatusMutationResult = Apollo.MutationResult<ChangeLicenceStatusMutation>;
export type ChangeLicenceStatusMutationOptions = Apollo.BaseMutationOptions<
    ChangeLicenceStatusMutation,
    ChangeLicenceStatusMutationVariables
>;
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput!) {
        createUser(input: $input)
    }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
    return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserPasswordDocument = gql`
    mutation updateUserPassword($input: UpdateUserPasswordInput!) {
        updateUserPassword(input: $input)
    }
`;
export type UpdateUserPasswordMutationFn = Apollo.MutationFunction<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>,
) {
    return Apollo.useMutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(
        UpdateUserPasswordDocument,
        baseOptions,
    );
}
export type UpdateUserPasswordMutationHookResult = ReturnType<typeof useUpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationResult = Apollo.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = Apollo.BaseMutationOptions<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
>;
export const UpdateDukeParametersDocument = gql`
    mutation updateDukeParameters($dukeParameters: DukeParametersInput!) {
        updateDukeParameters(dukeParameters: $dukeParameters)
    }
`;
export type UpdateDukeParametersMutationFn = Apollo.MutationFunction<
    UpdateDukeParametersMutation,
    UpdateDukeParametersMutationVariables
>;

/**
 * __useUpdateDukeParametersMutation__
 *
 * To run a mutation, you first call `useUpdateDukeParametersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDukeParametersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDukeParametersMutation, { data, loading, error }] = useUpdateDukeParametersMutation({
 *   variables: {
 *      dukeParameters: // value for 'dukeParameters'
 *   },
 * });
 */
export function useUpdateDukeParametersMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateDukeParametersMutation, UpdateDukeParametersMutationVariables>,
) {
    return Apollo.useMutation<UpdateDukeParametersMutation, UpdateDukeParametersMutationVariables>(
        UpdateDukeParametersDocument,
        baseOptions,
    );
}
export type UpdateDukeParametersMutationHookResult = ReturnType<typeof useUpdateDukeParametersMutation>;
export type UpdateDukeParametersMutationResult = Apollo.MutationResult<UpdateDukeParametersMutation>;
export type UpdateDukeParametersMutationOptions = Apollo.BaseMutationOptions<
    UpdateDukeParametersMutation,
    UpdateDukeParametersMutationVariables
>;
export const SaveDukeConversationDocument = gql`
    mutation saveDukeConversation($conversationId: String!, $historicalConversationDateMilli: Long) {
        saveDukeConversation(
            conversationId: $conversationId
            historicalConversationDateMilli: $historicalConversationDateMilli
        )
    }
`;
export type SaveDukeConversationMutationFn = Apollo.MutationFunction<
    SaveDukeConversationMutation,
    SaveDukeConversationMutationVariables
>;

/**
 * __useSaveDukeConversationMutation__
 *
 * To run a mutation, you first call `useSaveDukeConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDukeConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDukeConversationMutation, { data, loading, error }] = useSaveDukeConversationMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      historicalConversationDateMilli: // value for 'historicalConversationDateMilli'
 *   },
 * });
 */
export function useSaveDukeConversationMutation(
    baseOptions?: Apollo.MutationHookOptions<SaveDukeConversationMutation, SaveDukeConversationMutationVariables>,
) {
    return Apollo.useMutation<SaveDukeConversationMutation, SaveDukeConversationMutationVariables>(
        SaveDukeConversationDocument,
        baseOptions,
    );
}
export type SaveDukeConversationMutationHookResult = ReturnType<typeof useSaveDukeConversationMutation>;
export type SaveDukeConversationMutationResult = Apollo.MutationResult<SaveDukeConversationMutation>;
export type SaveDukeConversationMutationOptions = Apollo.BaseMutationOptions<
    SaveDukeConversationMutation,
    SaveDukeConversationMutationVariables
>;
