import React from 'react';
import { Message } from '@chatscope/chat-ui-kit-react';
import { Card, UploadFile } from 'antd';
import styled from 'styled-components/macro';
import { EntityFoundMessageComponent } from './EntityFoundMessageComponent';
import { UnknownIntentionMessageComponent } from './UnknownIntentionMessageComponent';
import { NormalMessageComponent } from './NormalMessageComponent';

export enum MessageDirection {
    Incoming = 'incoming',
    Outgoing = 'outgoing',
}

export enum MessageSender {
    User = 'user',
    Bot = 'bot',
}

const SuggestionCard = styled(Card)`
    margin: 1rem 1rem 0 0;
`;

export const commonMessageModel = (messageModelParams, messageModelContent: JSX.Element | null = null): JSX.Element => {
    return (
        <Message
            model={{
                sentTime: new Date().toString(),
                position: 'normal',
                ...messageModelParams,
            }}
        >
            {messageModelContent ? <Message.CustomContent>{messageModelContent}</Message.CustomContent> : null}
        </Message>
    );
};

export const buildSuggestionsMessage = (
    suggestionList: string[],
    sendMessage: (message: string, intention?: number, file?: any, displayMessage?: boolean) => void,
    uploadFile?: UploadFile,
) => {
    // TODO ndespouy set an enum of CHAT_INTENTION
    const file = uploadFile;
    const intention = file ? 2 : undefined;
    const handleSuggestionCardClick = (suggestion: string) => {
        sendMessage(suggestion, intention, file);
    };
    const messageModelParams = {
        message: '',
        type: 'custom',
        sender: MessageSender.Bot,
        direction: MessageDirection.Outgoing,
    };
    const messageModelContent = (
        <>
            {suggestionList.map((sl) => (
                <SuggestionCard hoverable onClick={() => handleSuggestionCardClick(sl)}>
                    {sl}
                </SuggestionCard>
            ))}
        </>
    );
    return commonMessageModel(messageModelParams, messageModelContent);
};

export const buildBotResponse = (messageParams: any): JSX.Element => {
    const defaultIncomingModel = {
        message: '',
        type: 'custom',
        sender: MessageSender.Bot,
        direction: MessageDirection.Incoming,
    };
    switch (parseInt(messageParams.intention, 10)) {
        case 0:
            return commonMessageModel(defaultIncomingModel, <UnknownIntentionMessageComponent />);
        case 1:
            return commonMessageModel(
                defaultIncomingModel,
                <EntityFoundMessageComponent messageParams={messageParams} />,
            );
        case 2:
        case 3:
        case 4:
        default:
            return commonMessageModel(defaultIncomingModel, <NormalMessageComponent messageParams={messageParams} />);
    }
};

export const buildUserMessage = (message: string): JSX.Element => {
    return commonMessageModel({
        message,
        sender: MessageSender.User,
        direction: MessageDirection.Outgoing,
    });
};
