import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import { CSVLink } from 'react-csv';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { DukeBarChart } from './chart/DukeBarChart';
import { DukeLineChart } from './chart/DukeLineChart';
import { DukeScatterChart } from './chart/DukeScatterChart';
import { buildChartData, COMMON_CHART_PROPS, DEFAULT_CHART } from './chart/chartUtils';

const DownloadButtonContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
`;

type Props = {
    chartParams: any;
};

const dataToCsv = (data) => {
    try {
        return data
            .filter((elt) => elt.name)
            .reduce(
                (result: any[], serie) => [...result, ...serie.data.map((elt) => ({ name: serie.name, ...elt }))],
                [],
            );
    } catch (e) {
        console.log(e);
        return [];
    }
};

const getFilename = () => {
    const date: Date = new Date();
    const fullYear = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const timestamp = `${fullYear}${month}${day}${hour}${minute}`;
    return `duke_chart_${timestamp}.csv`;
};

export const ChatChart = ({ chartParams }: Props) => {
    const data: any[] = useMemo(() => buildChartData(chartParams.series), [chartParams]);
    const chartType = chartParams.chart_type?.toLowerCase() || DEFAULT_CHART;
    const doDisplayChart: boolean = !!data.length && !(data.length < 2 && data[0].data?.length < 2);
    const chartContainerRef = useRef(null);
    const [chartWidth, setChartWidth] = useState(COMMON_CHART_PROPS.width);

    useEffect(() => {
        /** Resizer */
        const resizeChartContainer = () => {
            const chartContainerWidth = (chartContainerRef?.current as any).offsetWidth || COMMON_CHART_PROPS.width;
            setChartWidth(chartContainerWidth);
        };
        resizeChartContainer();
        window.addEventListener('resize', resizeChartContainer);

        /** Clean */
        return () => {
            window.removeEventListener('resize', resizeChartContainer);
        };
    }, []);

    return (
        <ResponsiveContainer width="100%" height="100%" ref={chartContainerRef}>
            {doDisplayChart ? (
                <>
                    <DownloadButtonContainer>
                        <CSVLink data={dataToCsv(data)} separator=";" filename={getFilename()}>
                            <Button>
                                <DownloadOutlined />
                            </Button>
                        </CSVLink>
                    </DownloadButtonContainer>
                    {chartType.indexOf('bar') >= 0 && <DukeBarChart data={data} width={chartWidth} />}
                    {chartType.indexOf('line') >= 0 && <DukeLineChart data={data} width={chartWidth} />}
                    {chartType.indexOf('scatter') >= 0 && <DukeScatterChart data={data} width={chartWidth} />}
                </>
            ) : (
                <></>
            )}
        </ResponsiveContainer>
    );
};
