import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { Button, Modal, UploadProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadFile } from 'antd/es/upload/interface';
import { DukeDragger } from '../../component/DukeDragger';

interface Props {
    visible: boolean;
    onSaveFile: (file: UploadFile) => void;
    onClose: any;
}

export default function UploadFileModal({ visible, onSaveFile, onClose }: Props) {
    const { t } = useTranslation();
    const [fileList, setFileList] = useState<UploadFile[]>();

    const onCloseModal = useCallback(() => {
        setFileList([]);
        onClose();
    }, [onClose, setFileList]);

    const sendFileAndQuestion = useCallback(() => {
        if (fileList?.length) {
            const file = fileList[0];
            if (file) {
                onSaveFile(file);
                onCloseModal();
            }
        }
    }, [fileList, onCloseModal, onSaveFile]);

    const draggerProps: UploadProps = useMemo(
        () => ({
            accept: '.xls, .xlsx, .xlsm, .xlsb, .csv, .jpeg, .jpg',
            maxCount: 1,
            fileList,
            action: (file) => {
                setFileList([file]);
                return '';
            },
        }),
        [fileList],
    );

    const onCancel = useCallback(() => {
        onCloseModal();
    }, [onCloseModal]);

    const footer: ReactNode = (
        <>
            <Button type="text" onClick={onCancel}>
                {t('common.cancel')}
            </Button>
            <Button type="primary" onClick={sendFileAndQuestion} disabled={!fileList?.length}>
                {t('common.ok')}
            </Button>
        </>
    );

    return (
        <Modal
            title={t('dukeChat.searchInUploadedFile.title')}
            visible={visible}
            onCancel={onCancel}
            width="40%"
            bodyStyle={{ maxHeight: '40%' }}
            footer={footer}
        >
            <DukeDragger {...{ draggerProps, hint: t('dukeChat.searchInUploadedFile.hint') }} />
        </Modal>
    );
}
