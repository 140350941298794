import { Divider, message, Slider, Switch, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ExclamationOutlined, LoadingOutlined } from '@ant-design/icons';
import { useChangeLicenceStatusMutation, useGetActualLicenceQuery } from '../../../graphql/duke.generated';
import { DUKE_ADMINISTRATOR_ROLE_URN } from '../../../conf/Global';
import { useListUsersQuery } from '../../../graphql/user.generated';
import { useListRolesQuery } from '../../../graphql/role.generated';
import { useUserContext } from '../../context/useUserContext';

const ContentContainer = styled.div`
    padding-top: 20px;
    padding-right: 40px;
    padding-left: 40px;
    width: 100%;
`;

const PageTitle = styled(Typography.Title)`
    && {
        margin-bottom: 12px;
    }
`;

const LineContainer = styled.div`
    margin-top: 10px;
`;

const LabelContainer = styled.div`
    width: 200px;
    display: inline-block;
`;

const SliderLabelContainer = styled(LabelContainer)`
    vertical-align: top;
    line-height: 2.5;
`;

const ValueContainer = styled.div`
    display: inline-block;
    font-size: larger;
`;

const SliderWidth = styled(Slider)`
    width: 200px;
    &.ant-slider-disabled {
        cursor: default;
        .ant-slider-handle,
        .ant-slider-mark-text,
        .ant-slider-dot {
            cursor: default !important;
        }
        .ant-slider-track {
            background-color: #1890ff !important;
        }
        .ant-slider-handle,
        .ant-slider-dot:first-child {
            border-color: #1890ff !important;
        }
    }
`;

const LicenceWarningIcon = styled(ExclamationOutlined)`
    color: #d39728;
`;

const buildMark = (markValue: number, label?: number) => {
    const mark = {};
    mark[markValue] = {
        style: {
            color: '#1890FF',
        },
        label: label && <strong>{label}</strong>,
    };
    return mark;
};

const initialMarks = buildMark(0);

export const DukeLicenceTab = () => {
    const { t } = useTranslation();
    const context = useUserContext();
    const authenticatedUser = context?.user;

    const [disabledActive, setDisabledActive] = useState(true);
    const [actualLicence, setActualLicence] = useState(null as any);
    const [marks, setMarks] = useState(initialMarks as any);
    const [numberOfAdmin, setNumberOfAdmin] = useState(0);
    const [numberOfAnalyst, setNumberOfAnalyst] = useState(0);
    const [numberOfLicence, setNumberOfLicence] = useState(0);
    const [maxNumberOfLicence, setMaxNumberOfLicence] = useState(0);

    const { data: licenceQueryData, error: licenceQueryError, refetch: licenceRefetch } = useGetActualLicenceQuery();

    const { error: rolesError, data: rolesData } = useListRolesQuery({
        fetchPolicy: 'no-cache',
        variables: {
            input: {
                start: 0,
                count: 10,
            },
        },
    });

    const { data: usersData, error: usersError } = useListUsersQuery({
        variables: { input: { start: 0, count: 10 } },
    });
    const [changeLicenceStatusMutation] = useChangeLicenceStatusMutation();

    const handleSetLicence = () => {
        if (licenceQueryData?.getActualLicence) {
            setActualLicence(licenceQueryData.getActualLicence);
            setDisabledActive(false);
        }
    };

    useEffect(handleSetLicence, [licenceQueryData]);

    useEffect(() => {
        if (actualLicence && rolesData && usersData) {
            // ADMINS
            let actualAdminNumber = 0;
            // Find admin role
            let adminRole: any = null;

            Object.values(rolesData?.listRoles?.roles).every((v, idx) => {
                if ((v as any).urn === DUKE_ADMINISTRATOR_ROLE_URN) {
                    adminRole = rolesData.listRoles.roles[idx];
                    return false;
                }
                return true;
            });
            if (adminRole) {
                actualAdminNumber = adminRole.users?.total || 0;
            }
            setNumberOfAdmin(actualAdminNumber);

            // ANALYSTES
            // Pour enlever le user de SuperDuke, on soustrait un à la fin.
            const actualAnalystNumber = Math.max((usersData.listUsers?.total || 0) - actualAdminNumber - 1, 0);
            setNumberOfAnalyst(actualAnalystNumber);

            // licence
            const maxNbrOfLicenceTemp = actualLicence.nbrOfAnalyst + actualLicence.nbrOfAdmin;
            const actualNumberOfLicence = actualAdminNumber + actualAnalystNumber;
            setMaxNumberOfLicence(maxNbrOfLicenceTemp);
            setNumberOfLicence(actualNumberOfLicence);

            // Build marks
            setMarks({
                ...buildMark(actualAdminNumber, actualAdminNumber),
                ...buildMark(actualNumberOfLicence, actualAdminNumber + actualAnalystNumber),
                ...buildMark(maxNbrOfLicenceTemp),
            });
        }
    }, [actualLicence, rolesData, usersData, t]);

    const handleActiveSwitchClick = () => {
        setDisabledActive(true);
        changeLicenceStatusMutation({
            variables: { dukeLicenceId: actualLicence?.licenceId ?? '' },
        })
            .catch((e) => {
                message.destroy();
                message
                    .error({
                        content: `${t('dukeLicence.updateLicenceStatusFailed')} \n ${e.message || ''}`,
                        duration: 3,
                    })
                    .then();
            })
            .finally(() => {
                licenceRefetch().then(() => {
                    handleSetLicence();
                });
                message
                    .success({
                        content: t('dukeLicence.updateLicenceStatusSuccess'),
                        duration: 3,
                    })
                    .then();
            });
    };

    return (
        <>
            {licenceQueryError && message.error(t('dukeLicence.failedToLoadLicence'))}
            {rolesError && message.error(t('dukeLicence.failedToLoadAdmins'))}
            {usersError && message.error(t('dukeLicence.failedToLoadUsers'))}
            <ContentContainer>
                <PageTitle level={3}>{t('dukeLicence.licenceTitle')}</PageTitle>
                <Typography.Paragraph type="secondary">{t('dukeLicence.licenceSubtitle')}</Typography.Paragraph>
                <Divider />
                <Typography.Title level={5}>{t('dukeLicence.dukeLicenceDetailTitle')}</Typography.Title>
                {!actualLicence || !rolesData ? (
                    <LoadingOutlined />
                ) : (
                    <>
                        <LineContainer>
                            <LabelContainer>
                                <Typography.Text
                                    style={{ fontWeight: 'bold', color: actualLicence?.isActive ? 'green' : 'red' }}
                                >
                                    {actualLicence?.isActive
                                        ? t('dukeLicence.isActiveTrue')
                                        : t('dukeLicence.isActiveFalse')}
                                </Typography.Text>
                            </LabelContainer>
                            <ValueContainer>
                                <Switch
                                    disabled={disabledActive || !authenticatedUser?.info?.isSuperAdmin}
                                    defaultChecked={!!actualLicence?.isActive}
                                    onClick={handleActiveSwitchClick}
                                />
                            </ValueContainer>
                        </LineContainer>
                        <LineContainer>
                            <LabelContainer>
                                <Typography.Text>{t('dukeLicence.licenceStartDate')}</Typography.Text>
                            </LabelContainer>
                            <ValueContainer>
                                <Typography.Text>
                                    {actualLicence &&
                                        new Date(actualLicence.licenceStartDateMilli).toLocaleDateString()}
                                </Typography.Text>
                            </ValueContainer>
                        </LineContainer>
                        <LineContainer>
                            <LabelContainer>
                                <Typography.Text>{t('dukeLicence.licenceEndDate')}</Typography.Text>
                            </LabelContainer>
                            <ValueContainer>
                                <Typography.Text>
                                    {actualLicence && new Date(actualLicence.licenceEndDateMilli).toLocaleDateString()}
                                </Typography.Text>
                                {actualLicence.licenceEndDateMilli - 2592000000 < new Date().getTime() && (
                                    <LicenceWarningIcon />
                                )}
                            </ValueContainer>
                        </LineContainer>
                        <LineContainer>
                            <SliderLabelContainer>
                                <Typography.Text>{t('dukeLicence.nbrOfAdmin')}</Typography.Text>
                            </SliderLabelContainer>
                            <ValueContainer>
                                <Typography.Text>{numberOfAdmin}</Typography.Text>
                            </ValueContainer>
                        </LineContainer>
                        <LineContainer>
                            <SliderLabelContainer>
                                <Typography.Text>{t('dukeLicence.nbrOfAnalyst')}</Typography.Text>
                            </SliderLabelContainer>
                            <ValueContainer>
                                <Typography.Text>{numberOfAnalyst}</Typography.Text>
                            </ValueContainer>
                        </LineContainer>
                        <LineContainer>
                            <SliderLabelContainer>
                                <Typography.Text>{t('dukeLicence.nbrOfLicence')}</Typography.Text>
                            </SliderLabelContainer>
                            <ValueContainer>
                                <Typography.Text>{maxNumberOfLicence}</Typography.Text>
                            </ValueContainer>
                        </LineContainer>
                        <LineContainer>
                            <ValueContainer>
                                <SliderWidth
                                    value={numberOfLicence}
                                    max={maxNumberOfLicence}
                                    tooltipVisible={false}
                                    included
                                    marks={marks}
                                    disabled
                                />
                            </ValueContainer>
                        </LineContainer>
                        {/* <LineContainer> */}
                        {/*    <SliderLabelContainer><Typography.Text>{t('dukeLicence.nbrOfAnalyst')}</Typography.Text></SliderLabelContainer> */}
                        {/*    <ValueContainer> */}
                        {/*        <SliderWidth value={analystNbr} */}
                        {/*                     max={actualLicence.nbrOfAnalyst} */}
                        {/*                     included */}
                        {/*                     marks={analystMarks} disabled/> */}
                        {/*    </ValueContainer> */}
                        {/* </LineContainer> */}
                    </>
                )}
                {/* <Divider/> */}
                {/* <Typography.Title level={5}>{t('dukeLicence.dukeLicenceUserTitle')}</Typography.Title> */}
                {/* {!actualLicence ? <LoadingOutlined/> : <></>} */}
            </ContentContainer>
        </>
    );
};
