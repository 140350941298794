import React from 'react';
import { Typography, Row } from 'antd';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ManageAccount } from '../shared/ManageAccount';
import { useEntityRegistry } from '../useEntityRegistry';
import { EntityType } from '../../types.generated';
import { HeaderLinks } from '../shared/admin/HeaderLinks';
import { useIsShowAcrylInfoEnabled } from '../useAppConfig';
import { useUserContext } from '../context/useUserContext';
import DemoButton from '../entity/shared/components/styled/DemoButton';
import { DukeLogo } from '../duke/component/DukeLogo';

const WelcomeText = styled(Typography.Text)`
    font-size: 16px;
    color: ${(props) =>
        props.theme.styles['homepage-text-color'] || props.theme.styles['homepage-background-lower-fade']};
`;

const navBarStyle = {
    padding: '10px 20px',
    borderBottom: '1px solid rgb(233, 233, 233)',
};

const NavGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const HomePageHeaderNavBar = () => {
    const entityRegistry = useEntityRegistry();
    const { t } = useTranslation(['translation', 'theme']);
    const userContext = useUserContext();
    const showAcrylInfo = useIsShowAcrylInfoEnabled();
    const { user } = userContext;

    return (
        <Row justify="space-between" style={navBarStyle}>
            <WelcomeText>
                <DukeLogo style={{ width: 40, marginRight: '3rem' }} />
                {!!user && (
                    <>
                        {t('home.welcomeBack')}, <b>{entityRegistry.getDisplayName(EntityType.CorpUser, user)}</b>.
                    </>
                )}
            </WelcomeText>
            <NavGroup>
                <HeaderLinks />
                <ManageAccount
                    urn={user?.urn || ''}
                    pictureLink={user?.editableProperties?.pictureLink || ''}
                    name={(user && entityRegistry.getDisplayName(EntityType.CorpUser, user)) || undefined}
                />
                {showAcrylInfo && <DemoButton />}
            </NavGroup>
        </Row>
    );
};
