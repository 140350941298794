import { Divider, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DUKE_APP_VERSION } from '../../../conf/Global';

const ContentContainer = styled.div`
    padding-top: 20px;
    padding-right: 40px;
    padding-left: 40px;
    width: 100%;
`;

const PageTitle = styled(Typography.Title)`
    && {
        margin-bottom: 12px;
    }
`;

const LineContainer = styled.div``;

const LabelContainer = styled.div`
    width: 200px;
    display: inline-block;
`;

const ValueContainer = styled.div`
    display: inline-block;
`;

export const ComplementaryInfo = () => {
    const version = DUKE_APP_VERSION;
    const { t } = useTranslation();

    return (
        <ContentContainer>
            <PageTitle level={3}>{t('dukeLicence.complementaryInfoTitle')}</PageTitle>
            <Typography.Paragraph type="secondary">{t('dukeLicence.complementaryInfoSubtitle')}</Typography.Paragraph>
            <Divider />
            <Typography.Title level={5}>{t('dukeLicence.dukeVersionTitle')}</Typography.Title>
            <LineContainer>
                <LabelContainer>
                    <Typography.Text>{t('dukeLicence.version')} </Typography.Text>
                </LabelContainer>
                <ValueContainer>
                    <Typography.Text strong>{version}</Typography.Text>
                </ValueContainer>
            </LineContainer>
            <Divider />
        </ContentContainer>
    );
};
