import React from 'react';
import { Modal, List, message } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useUpdateDukeParametersMutation } from '../../../graphql/duke.generated';
import { DukeParametersInput } from '../../../types.generated';
import { LANGUAGE_LIST } from '../../../conf/Global';
import { useUserContext } from '../../context/useUserContext';

type Props = {
    visible: boolean;
    onClose: () => void;
};

const ListItem = styled(List.Item)`
    &&& {
        cursor: pointer;
        &:hover {
            background: #f5f5f5;
        }
    }
`;

export default function ChangeLanguageModal({ visible, onClose }: Props) {
    const { t, i18n } = useTranslation();
    const user = useUserContext()?.user;
    const [updateDukeParametersMutation] = useUpdateDukeParametersMutation();

    const onChangeLanguage = (language: string): any => {
        if (language !== i18n.language) {
            i18n.changeLanguage(language).then();

            // Save language for user
            const dukeParametersInput: DukeParametersInput = {
                userId: user?.username || '',
                language,
            };
            updateDukeParametersMutation({
                variables: { dukeParameters: dukeParametersInput },
            })
                .catch((e) => {
                    message.destroy();
                    message
                        .error({
                            content: `${t('crud.error.update')} \n ${e.message || ''}`,
                            duration: 3,
                        })
                        .then();
                })
                .finally(() => {
                    message
                        .success({
                            content: t('crud.success.update'),
                            duration: 3,
                        })
                        .then();
                });
        }
        onClose();
    };

    return (
        <Modal
            title={t('home.changeLanguageTitle')}
            visible={visible}
            onCancel={onClose}
            okText={t('common.save')}
            cancelText={t('common.cancel')}
        >
            <List
                bordered
                dataSource={LANGUAGE_LIST}
                renderItem={(item) => <ListItem onClick={() => onChangeLanguage(item.value)}>{item.label}</ListItem>}
            />
        </Modal>
    );
}
